import i18n from "@/i18n";
import { dateSortFunction } from "@/tools/functions";
import { CellType, DataType, FilterType } from '@colven/common-domain-lib/lib';
import { compareNumberSplitString } from "../tools/functions";

export const PERFORMANCE_MAP_POINT_JSON_TEMPALTE = {
    id: null,
    lat: null,
    lng: null,
    value: "TRUCK",
    filters: [],
    marker: {
        title: "",
        type: "img",
        ref: "truck-icon.svg",
        size: 40,
        tooltipDirection: "right",
        tooltipPermanent: false,
        tooltipBorderColor: "rgb(0,176,240)",
        businessData: {
            number: 0,
            direction: "northeast",
            stopped: false
        }
    }
};

export const PERFORMANCE_MAP_JSON_TEMPLATE = {
    name: i18n.t("performanceSummary.map.name"),
    groups: [{
        name: i18n.t("performanceSummary.map.groupName"),
        title: i18n.t("performanceSummary.map.groupTitle"),
        measure: undefined,
        referenceByInterval: false,
        series: [{
            type: "MARKERS",
            points: []
        }],
        references: [],
        exclusive: true,
        displayOnStart: true
    }],
    filters: [],
    info: {}
};

export const PERFORMANCE_MAP_REFERENCE_TEMPLATE = {
    "name": null,
    "color": "rgb(0,176,240)",
    "isInterval": false,
    "title": null
};
// FIXME: Empezar por agregar aqui los headers para el tiempo

export const COMMON_TABLE_HEADERS_PERFORMANCE = [
    {
        text: "table.headers.performance.nameThing",
        align: "left",
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.string,
        value: "thingName",
        width: 220,
    },
    {
        text: "table.headers.performance.serviceType",
        align: "left",
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.string,
        value: "serviceType",
        width: 220,
    },
    {
        text: "table.headers.performance.nameDriver",
        align: "left",
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.string,
        value: "nameDriver",
        width: 220,
    },
    {
        text: "table.headers.performance.positionTimestamp",
        align: "left",
        filterType: FilterType.textField,
        sort: (a, b) => { return dateSortFunction(a, b, 'DD/MM/YYYY HH:mm:ss') },
        selected: true,
        sortable: true,
        type: DataType.string,
        cellType: CellType.textBackgroundColor,
        cellConfig: {
            round: false,
            outlined: true,
            color: "positionTimestampColor",
        },
        value: "positionTimestamp",
        width: 220,
    },
    {
        text: "table.headers.performance.communicationTimestamp",
        align: "left",
        filterType: FilterType.textField,
        sort: (a, b) => { return dateSortFunction(a, b, 'DD/MM/YYYY HH:mm:ss') },
        selected: true,
        sortable: true,
        type: DataType.string,
        cellType: CellType.textBackgroundColor,
        cellConfig: {
            round: false,
            outlined: true,
            color: "communicationTimestampColor",
        },
        value: "communicationTimestamp",
        width: 220,
    },
    {
        text: "table.headers.performance.consumedFuel",
        align: "left",
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.string,
        value: "consumedFuel",
        width: 220,
        sort: (a, b) => { return compareNumberSplitString(a, b, " ") }
    },
    {
        text: "table.headers.performance.performanceByLiter",
        align: "left",
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.string,
        value: "performanceByLiter",
        width: 220,
        sort: (a, b) => { return compareNumberSplitString(a, b, " ") }
    },
    {
        text: "table.headers.performance.fuelLevelStart",
        align: "left",
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.string,
        value: "fuelLevelStart",
        width: 220,
        sort: (a, b) => { return compareNumberSplitString(a, b, " ") }
    },
    {
        text: "table.headers.performance.fuelLevelEnd",
        align: "left",
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.string,
        value: "fuelLevelEnd",
        width: 220,
        sort: (a, b) => { return compareNumberSplitString(a, b, " ") }
    },
    {
        text: "table.headers.performance.lowestLevel",
        align: "left",
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.string,
        value: "lowestLevel",
        width: 220,
        sort: (a, b) => { return compareNumberSplitString(a, b, " ") }
    },
    {
        text: "table.headers.performance.highestLevel",
        align: "left",
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.string,
        value: "highestLevel",
        width: 220,
        sort: (a, b) => { return compareNumberSplitString(a, b, " ") }
    },
    {
        text: "table.headers.performance.averageSpeed",
        align: "left",
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.string,
        value: "averageSpeed",
        width: 220,
        sort: (a, b) => { return compareNumberSplitString(a, b, " ") }
    },
    {
        text: "table.headers.performance.highestSpeed",
        align: "left",
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.string,
        value: "highestSpeed",
        width: 225,
        sort: (a, b) => { return compareNumberSplitString(a, b, " ") }
    },
    {
        text: "table.headers.performance.geoReference",
        align: "left",
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.string,
        value: "geoReference",
        width: 220,
    },
];

export const ODOMETER_TABLE_HEADER_COMPONENTS = [{
    text: "table.headers.performance.consumedEvery100km",
    align: "left",
    filterType: FilterType.textField,
    selected: true,
    sortable: true,
    type: DataType.string,
    value: "consumedEvery100km",
    width: 220,
    sort: (a, b) => { return compareNumberSplitString(a, b, " ") }
},];

export const HOURMETER_TABLE_HEADER_COMPONENTS = [
    {
        text: "table.headers.performance.consumedEvery10Hs",
        align: "left",
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.string,
        value: "consumeEvery10Hs",
        width: 220,
        sort: (a, b) => { return compareNumberSplitString(a, b, " ") }
    },
];

export const TABLE_DATA_PERFORMANCE_TEMPLATE = {
    thingId: null,
    thingName: null,
    serviceTypeKey: null,
    serviceType: null,
    nameDriver: null,
    positionTimestamp: null,
    communicationTimestamp: null,
    positionTimestampColor: null,
    communicationTimestampColor: null,
    consumedFuel: null,
    consumedEvery100km: null,
    performanceByLiter: null,
    fuelLevelStart: null,
    fuelLevelEnd: null,
    lowestLevel: null,
    highestLevel: null,
    averageSpeed: null,
    highestSpeed: null,
    geoReference: null
};

export const CHART_TYPE = {
    PERFORMANCE: "performance",
    CONSUME: "consume",
    DISTANCE: "distance",
    HOURS: "hours"
};

export const CHART_DATASET_TEMPLATE = {
    label: "performanceSummary.chart.dataset.label.",
    data: [],
    backgroundColor: "#D6E9C6",
    type: undefined,
    dataProperty: undefined,
    originalData: [],
    formattedTooltipData: {
        label: []
    },
    formattedLabelData: [],
    fill: true,
    yAxisID: undefined,
    lineTension: 0
};

export const CHART_JSON_TEMPLATE = {
    type: "bar",
    name: "",
    data: {
        labels: [],
        datasets: [],
    },
    options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: false,
        scales: {
            xAxes: [
                {
                    stacked: true,
                    ticks: {
                        beginAtZero: true,
                    }
                },
            ],
            yAxes: [
                {
                    stacked: true,
                    ticks: {
                        beginAtZero: true,
                    },
                    scaleLabel: {
                        display: true,
                        labelString: ""
                    }
                },
            ],
        },
        tooltips: {
            mode: 'index'
        },
        plugins: {
            zoom: {
                pan: {
                    enabled: true,
                    mode: "xy",
                },
                zoom: {
                    enabled: true,
                    mode: "xy",
                },
            },
        },
    },
};
export const PERFORMANCE_CHAT_JSON_TEMPLATE = {
    type: 'bar',
    data: {
        labels: [],
        datasets: []
    },
    options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: false,
        scales: {
            xAxes: [{
                stacked: true,
                "ticks": {
                    "beginAtZero": true
                },
            }],
            yAxes: [{
                stacked: true,
                "ticks": {
                    "beginAtZero": true
                },
            }]
        },
        tooltips: {},
        "plugins": {
            "zoom": {
                "pan": {
                    "enabled": true,
                    "mode": "xy"
                },
                "zoom": {
                    "enabled": true,
                    "mode": "xy"
                }
            }
        }
    }
};

export const PERFORMANCE_CHART_DATASET_TEMPLATE = {
    label: '',
    data: [],
    backgroundColor: '#D6E9C6',
    type: undefined,
    dataProperty: undefined,
    originalData: [],
    formattedTooltipData: {
        label: []
    },
    formattedLabelData: [],
    fill: true,
    yAxisID: undefined,
    lineTension: 0
};

export const TANK_KEY_FIELD = {
    "TANK_1": "fuelLevel1",
    "TANK_2": "fuelLevel2",
    "TANK_3": "fuelLevel3",
    "TANK_4": "fuelLevel4"
}

export const FUEL_LEVEL_KEY_TO_TANK_KEY = {
    "fuelLevel1": "TANK_1",
    "fuelLevel2": "TANK_2",
    "fuelLevel3": "TANK_3",
    "fuelLevel4": "TANK_4"
}

export const TANK_KEY_CONFIG_FIELD = {
    "TANK_1": "tank1",
    "TANK_2": "tank2",
    "TANK_3": "tank3",
    "TANK_4": "tank4"
}