import { performanceApi } from '@/api/performanceApi';
import thingApi from '@/api/thing.api';
import { configurationService } from "@/business/configurationService";
import { eventsService } from "@/business/eventsService";
import { CONVERSIONS_DISTANCE, CONVERSIONS_LITERS, reportsColors } from "@/constants/constants";
// eslint-disable-next-line no-unused-vars
import { CHART_DATASET_TEMPLATE, CHART_JSON_TEMPLATE, CHART_TYPE, PERFORMANCE_MAP_JSON_TEMPLATE, PERFORMANCE_MAP_POINT_JSON_TEMPALTE, PERFORMANCE_MAP_REFERENCE_TEMPLATE, TABLE_DATA_PERFORMANCE_TEMPLATE } from "@/constants/performance.constant";
import i18n from "@/i18n";
import store from "@/store/store";
import { ReportConversionUtil, TimeRanges, YieldUnits, unitParser} from '@colven/common-domain-lib/lib';
import { VARILLA_REPORT_BY } from '@colven/common-domain-lib/lib/constants/reports-constants';
import dateformat from 'dateformat';
import * as deepcopy from "deepcopy";
import { COMMON_TABLE_HEADERS_PERFORMANCE, HOURMETER_TABLE_HEADER_COMPONENTS, ODOMETER_TABLE_HEADER_COMPONENTS, TANK_KEY_CONFIG_FIELD } from '../constants/performance.constant';
import { calcPerformance, getYieldUnit, parseTimestamp, parseToGallonPerMiles, processFilters } from "../tools/functions";

export const performanceService = (function () {

    const LITER_TO_GALLON = 0.26417205;
    const KM_TO_MILES = 0.62137119;

    async function getPerformanceSummary(filters) {
        const filterData = processFilters(filters);
        let data = null;
        if (filterData.reporFixedKey === TimeRanges.CUSTOM) {
            filterData.originalFilters = filters;
            await performanceApi.getPerformanceDataCustomTime(filterData);
            return null;
        } else {
            data = await performanceApi.getPerformanceDataFixedRange(filterData);
        }
        return await generateReportByData(data, filters);
    }

    async function generateReportByData(data, filters) {
        const filterData = processFilters(filters);

        const result = {};
        const { chart, map, table, initPosition } = await processReportData(data, filterData);
        result.table = table;
        result.maps = map;
        result.chart = chart;
        result.initPosition = initPosition;
        return result;
    }
    async function processReportData(data, filter) {
        let litersConversion = CONVERSIONS_LITERS[filter.yieldUnit];
        let performanceConversion = getYieldUnit(filter.yieldUnit);
        let distanceConversion = CONVERSIONS_DISTANCE[filter.yieldUnit];
        const shouldUseGallons = filter.yieldUnit === YieldUnits.GALLONS_PER_HOUR || filter.yieldUnit === YieldUnits.HOURS_PER_GALLON || filter.yieldUnit === YieldUnits.MILES_PER_GALLON
        // CHARTS init
        const performanceChart = initChart(CHART_TYPE.PERFORMANCE, 0);
        const consumeChart = initChart(CHART_TYPE.CONSUME, 1);
        const distanceChartType = filter.reportBy === 'ODOMETER' ? CHART_TYPE.DISTANCE : CHART_TYPE.HOURS
        const distanceChart = initChart(distanceChartType, 2);

        //MAP init
        const map = deepcopy(PERFORMANCE_MAP_JSON_TEMPLATE);

        //BASE TABLE init
        const tableHeaders = deepcopy(COMMON_TABLE_HEADERS_PERFORMANCE);

        for (const header of tableHeaders) {
            if (shouldUseGallons && header.text === "table.headers.performance.performanceByLiter") {
                header["text"] = i18n.t("table.headers.performance.performanceByGallon");
            } else {
                header["text"] = i18n.t(header["text"]);
            }
        }

        if (filter.reportBy === VARILLA_REPORT_BY.HOURMETER) {
            const hourmeterHeaders = deepcopy(HOURMETER_TABLE_HEADER_COMPONENTS);
            for (const header of hourmeterHeaders) {
                header["text"] = i18n.t(header["text"]);
                tableHeaders.push(header);
            }
        }
        else {
            const odometerHeaders = deepcopy(ODOMETER_TABLE_HEADER_COMPONENTS);
            for (const header of odometerHeaders) {
                if (shouldUseGallons && header.text === 'table.headers.performance.consumedEvery100km') {
                    header["text"] = i18n.t("table.headers.performance.consumedEvery100Mi");
                } else {
                    header["text"] = i18n.t(header["text"]);
                }
                tableHeaders.push(header);
            }
        }
        const tableDataFinal = [];

        //init position
        let initPosition = {
            lat: null,
            long: null,
            zoom: 2.5
        }

        const mapConfig = await configurationService.get("performanceSummary.map");
        if (mapConfig) {
            initPosition.currentLayer = mapConfig.data.currentLayer;
        }

        if (data && data.length > 0) {
            data.forEach((thing, index) => {
                //CONVERSIONES DE UNIDADES
                let consumed = thing.consumed || 0;
                let distance = thing.distance || 0;
                let time = thing.timeRunning || 0;
                let performanceValue;
                let performanceByLiter;
                let consumed100km = 0;
                let consumed10Hs;
                let isHourometer = (filter.reportBy === VARILLA_REPORT_BY.HOURMETER)
                if (isHourometer) {
                    distance = (time / 3600);      
                    consumed10Hs = (unitParser.parseVolumen(consumed,store.getters['user/getInternationalization'].unit,false) / distance) * 10;                
                } else {
                    consumed100km = ((unitParser.parseVolumen(consumed,store.getters['user/getInternationalization'].unit,false) / unitParser.parseDistance(distance,store.getters['user/getInternationalization'].unit,false))) * 100;
                    if (shouldUseGallons) {                        
                        consumed100km = (((consumed * 0.264172) / (distance * 0.621371))) * 100;
                    } else {
                        consumed100km = calcPerformance(consumed, distance, YieldUnits.LITERS_PER_100KM);
                    }
                }
                performanceByLiter = calcPerformance(consumed, distance, filter.yieldUnit);
                performanceValue = calcPerformance(consumed, distance, filter.yieldUnit);

                let averageSpeed = (thing.totalSpeed && thing.totalTracksSpeed && (thing.totalSpeed / thing.totalTracksSpeed || 0.0) || 0);
                let maxSpeed = thing.maxSpeed || 0;
                let minFuelLevel = thing.minFuelLevel;
                let maxFuelLevel = thing.maxFuelLevel;
                let firstFuelLevel = thing.firstFuelLevel;
                let lastFuelLevel = thing.lastFuelLevel;

                if (filter.yieldUnit === YieldUnits.MILES_PER_GALLON) {
                    // consumed100km = parseToGallonPerMiles(consumed100km);
                    consumed = consumed * LITER_TO_GALLON; //1 litro = 0,26417205 galones (US)
                    averageSpeed = averageSpeed * KM_TO_MILES; //1km/h = 0,62137119 millas/h
                    maxSpeed = maxSpeed * KM_TO_MILES;
                    distance = distance * KM_TO_MILES;
                    minFuelLevel = minFuelLevel * LITER_TO_GALLON;
                    maxFuelLevel = maxFuelLevel * LITER_TO_GALLON;
                    firstFuelLevel = firstFuelLevel * LITER_TO_GALLON;
                    lastFuelLevel = lastFuelLevel * LITER_TO_GALLON;
                }

                if (filter.yieldUnit === YieldUnits.GALLONS_PER_HOUR || filter.yieldUnit === YieldUnits.HOURS_PER_GALLON) {
                    consumed = consumed * LITER_TO_GALLON; //1 litro = 0,26417205 galones (US)
                    minFuelLevel = minFuelLevel * LITER_TO_GALLON;
                    maxFuelLevel = maxFuelLevel * LITER_TO_GALLON;
                    firstFuelLevel = firstFuelLevel * LITER_TO_GALLON;
                    lastFuelLevel = lastFuelLevel * LITER_TO_GALLON;
                }

                //CHARTS
                performanceChart.data.labels.push(thing.thingName);
                performanceChart.data.datasets[0].data.push(performanceValue > 0 ? performanceValue : 0);
                performanceChart.data.datasets[0].formattedTooltipData.label.push(performanceValue + " " + performanceConversion);

                consumeChart.data.labels.push(thing.thingName);
                consumeChart.data.datasets[0].data.push(consumed.toFixed(2) > 0 ? consumed.toFixed(2) : 0);
                consumeChart.data.datasets[0].formattedTooltipData.label.push(consumed.toFixed(2) + " " + litersConversion);

                distanceChart.data.labels.push(thing.thingName);
                distanceChart.data.datasets[0].data.push(distance.toFixed(2) > 0 ? distance.toFixed(2) : 0);
                distanceChart.data.datasets[0].formattedTooltipData.label.push(distance.toFixed(2) + " " + distanceConversion);

                //MAP
                const reference = deepcopy(PERFORMANCE_MAP_REFERENCE_TEMPLATE);
                reference.name = thing.thingName;
                reference.title = thing.thingName;
                map.groups[0].references.push(reference);
                const thingName = thing.thingName;
                const lastTrackTimestampTranslation = i18n.t("performanceSummary.map.popup.lastTrackTimestamp");
                const lastTrackTimestampValue = parseTimestamp(thing.lastTrackTimestamp);
                const lastTrackTimestampColor = ReportConversionUtil.getTimestampColor(thing.lastTrackTimestamp);
                const lastCommunicationTimestampTranslation = i18n.t("performanceSummary.map.popup.lastCommunicationTimestamp");
                const lastCommunicationTimestampValue = parseTimestamp(thing.lastComunicationTimestamp);
                const lastCommunicationTimestampColor = ReportConversionUtil.getTimestampColor(thing.lastComunicationTimestamp);
                const goToGoogleTranslation = i18n.t("performanceSummary.map.popup.goToGoogleTranslation");

                if (thing.position && thing.position.lat && thing.position.lng) {
                    const thingPoint = deepcopy(PERFORMANCE_MAP_POINT_JSON_TEMPALTE);
                    thingPoint.id = thing.idThing;
                    thingPoint.lat = thing.position.lat;
                    thingPoint.lng = thing.position.lng;
                    thingPoint.value = thingName;
                    thingPoint.marker.businessData = { hasLinks: true };
                    thingPoint.marker.businessData.popup = getMapPopupTemplate(thingName, lastTrackTimestampTranslation, lastTrackTimestampColor, lastTrackTimestampValue, lastCommunicationTimestampTranslation, lastCommunicationTimestampColor, lastCommunicationTimestampValue, goToGoogleTranslation, thing.position.lat, thing.position.lng);
                    map.groups[0].series[0].points.push(thingPoint);
                    thingPoint.marker.businessData.number = index + 1;
                    initPosition.lat = thing.position.lat;
                    initPosition.long = thing.position.lng;
                }

                //TABLE
                const tableData = deepcopy(TABLE_DATA_PERFORMANCE_TEMPLATE);
                tableData.thingId = thing.idThing;
                tableData.thingName = thing.thingName;
                tableData.serviceTypeKey = thing.serviceTypeKey;
                tableData.serviceType = thing.serviceTypeKey && thing.serviceTypeKey !== '' ? i18n.t("table.data.serviceType." + thing.serviceTypeKey) : '';
                tableData.nameDriver = thing.currentDriverName;
                tableData.positionTimestamp = parseTimestamp(thing.lastTrackTimestamp);
                tableData.communicationTimestamp = parseTimestamp(thing.lastComunicationTimestamp);
                tableData.fromString = parseTimestamp(thing.from);
                tableData.toString = parseTimestamp(thing.to);
                tableData.positionTimestampColor = ReportConversionUtil.getTimestampColor(thing.lastTrackTimestamp);
                tableData.communicationTimestampColor = ReportConversionUtil.getTimestampColor(thing.lastComunicationTimestamp);
                tableData.number = index + 1;

                tableData.consumedFuel = consumed ? consumed.toFixed(2) + " " + litersConversion : null;
                if (time > 0) {
                    tableData.consumeEvery10Hs = consumed10Hs.toFixed(2) + " " + litersConversion;
                } else {
                    tableData.consumedEvery100km = consumed100km.toFixed(2) + " " + litersConversion;
                }
                tableData.distance = isHourometer ? distance.toFixed(2) + ' Hs' : distance.toFixed(2) + " " + distanceConversion;
                tableData.performanceByLiter = `${performanceByLiter} ${performanceConversion}`;
                tableData.performanceValue = performanceValue;
                tableData.fuelLevelStart = firstFuelLevel ? firstFuelLevel.toFixed(2) + " " + litersConversion : null;
                tableData.fuelLevelEnd = lastFuelLevel ? lastFuelLevel.toFixed(2) + " " + litersConversion : null;
                tableData.lowestLevel = minFuelLevel ? minFuelLevel.toFixed(2) + " " + litersConversion + " - " + parseTimestamp(thing.minFuelLevelTimestamp) : null;
                tableData.highestLevel = maxFuelLevel ? maxFuelLevel.toFixed(2) + " " + litersConversion + " - " + parseTimestamp(thing.maxFuelLevelTimestamp) : null;
                distanceConversion = unitParser.getUnit(store.getters['user/getInternationalization'].unit, unitParser.UNITS_NAMES.DISTANCE); // Esto es para evitar que quede como hs/hs                 
                let speedConversion = unitParser.getUnit(store.getters['user/getInternationalization'].unit, unitParser.UNITS_NAMES.SPEED);
                tableData.averageSpeed = Number(averageSpeed) >= 0 ? averageSpeed.toFixed(2) + " " + speedConversion : null;
                tableData.highestSpeed = Number(maxSpeed) >= 0 ? maxSpeed.toFixed(2) + " " + speedConversion + " - " + parseTimestamp(thing.maxSpeedTimestamp) : null;
                tableData.geoReference = thing.geoReference;
                tableData.position = thing.position ? thing.position : {};
                tableData.positions = thing.positions ? thing.positions : [];

                tableDataFinal.push(tableData);
            })
        }
        if (!initPosition.lat) {
            initPosition = null;
        }
        return {
            chart: [performanceChart, consumeChart, distanceChart],
            map: [map],
            table: {
                headers: tableHeaders,
                data: tableDataFinal
            },
            initPosition
        }
    }

    async function getPerformanceDashboard(filters) {
        const filterData = processFilters(filters);
        const data = await performanceApi.getPerformanceDataFixedRange(filterData);
        return generateCharts(data, filterData);
    }

    function initChart(chartType, value) {
        // CHART init
        const chart = deepcopy(CHART_JSON_TEMPLATE);
        chart.id = new Date().getTime() + value;
        chart.name = i18n.t(chartType);
        chart.data.datasets = getInitDataset(chartType);
        return chart;
    }

    function generateCharts(data, filterData) { 
        let litersConversion = '';
        let performanceConversion = '';
        let distanceConversion = '';
        if (filterData && filterData.reportBy && filterData.reportBy === 'HOURMETER') {
            litersConversion = CONVERSIONS_LITERS[filterData.yieldUnit];
            performanceConversion = getYieldUnit(filterData.yieldUnit);
            distanceConversion = CONVERSIONS_DISTANCE.hours;
        } else {
            litersConversion = CONVERSIONS_LITERS[filterData.yieldUnit];
            performanceConversion = getYieldUnit(filterData.yieldUnit);
            distanceConversion = CONVERSIONS_DISTANCE[filterData.yieldUnit];
        }

        const performanceChart = initChart(CHART_TYPE.PERFORMANCE, 0);
        const consumeChart = initChart(CHART_TYPE.CONSUME, 1);
        const distanceChartType = filterData.reportBy === 'ODOMETER' ? CHART_TYPE.DISTANCE : CHART_TYPE.HOURS
        const distanceChart = initChart(distanceChartType, 2);

        if (data && data.length > 0) {
            for (const thing of data) {
                processChartThing(performanceChart, thing, filterData.yieldUnit, CHART_TYPE.PERFORMANCE, performanceConversion, filterData.reportBy);
                processChartThing(consumeChart, thing, filterData.yieldUnit, CHART_TYPE.CONSUME, litersConversion, filterData.reportBy);
                processChartThing(distanceChart, thing, filterData.yieldUnit, CHART_TYPE.DISTANCE, distanceConversion, filterData.reportBy);
            }
        }
        return [performanceChart, consumeChart, distanceChart];
    }

    function processChartThing(chart, thing, yieldUnit, chartType, unit, reportBy) {
        chart.data.labels.push(thing.thingName);
        let dataResult = 0.0;
        let dataPartialResult = 0.0;
        const shouldUseGallons = yieldUnit === YieldUnits.GALLONS_PER_HOUR || yieldUnit === YieldUnits.HOURS_PER_GALLON || yieldUnit === YieldUnits.MILES_PER_GALLON
        const performanceMeasure = reportBy === VARILLA_REPORT_BY.HOURMETER ? (thing.timeRunning / 3600) : thing.distance;
        switch (chartType) {
            case CHART_TYPE.PERFORMANCE:
                dataPartialResult = calcPerformance(thing.consumed, performanceMeasure, yieldUnit);
                dataResult = dataPartialResult > 0 ? dataPartialResult : 0;
                break;
            case CHART_TYPE.CONSUME:
                dataPartialResult = thing.consumed ? shouldUseGallons ? (thing.consumed * 0.26417205).toFixed(2)
                    : thing.consumed.toFixed(2)
                    : 0;
                dataResult = dataPartialResult > 0 ? dataPartialResult : 0;
                break;
            case CHART_TYPE.DISTANCE:
            case CHART_TYPE.HOURS:
                dataPartialResult =
                    thing.distance ?
                        yieldUnit === YieldUnits.MILES_PER_GALLON
                            ? (thing.distance * 0.62137119).toFixed(2)
                            : thing.distance.toFixed(2)
                        : 0;
                dataResult = dataPartialResult > 0 ? dataPartialResult : 0;
                dataResult = reportBy === VARILLA_REPORT_BY.HOURMETER ? (performanceMeasure).toFixed(2) : dataResult;
                break;
            default:
                break;
        }
        chart.data.datasets[0].data.push(dataResult);
        chart.data.datasets[0].formattedTooltipData.label.push(`${dataResult} ${unit}`);
    }

    function getInitDataset(chartType) {
        const dataset = deepcopy(CHART_DATASET_TEMPLATE);
        dataset.label = unitParser.changeUnit(dataset.label, unitParser.UNITS_NAMES.VOLUME, store.getters['user/getInternationalization'].unit, function(text) {return i18n.t(text) })
	
        switch (chartType) {
            case CHART_TYPE.PERFORMANCE:
                dataset.backgroundColor = reportsColors.PERFORMANCE;
                break;
            case CHART_TYPE.CONSUME:
                dataset.backgroundColor = reportsColors.CONSUMPTION;
                break;
            case CHART_TYPE.DISTANCE:
                dataset.backgroundColor = reportsColors.DISTANCE;
                break;
            case CHART_TYPE.HOURS:
                dataset.backgroundColor = reportsColors.DISTANCE;
                break;
            default:
                dataset.backgroundColor = reportsColors.PERFORMANCE;
                break;
        }

        dataset.label = i18n.t(dataset.label + chartType);
        return [dataset];
    }

    function getDetailsData(thingSelected, data) {
        const result = data.find((thingData) => { return thingData.thingId === thingSelected.thingId });
        return result;
    }

    async function getPerformanceChartComparation(currentThing, filters) {
        const currentThingData = currentThing;
        const filterData = processFilters(filters);
        const data = await performanceApi.getPerformanceDataFixedRangeByThingTypeAndBrand({ idThing: currentThing.thingId, reportFixedKey: filterData.reporFixedKey });
        let things = calculatePerformanceData(data, filterData.yieldUnit, filterData.reportBy);
        things = things.filter((thing) => { return thing.thingName !== currentThing.thingName });
        things.push(currentThingData);
        const performanceConversion = getYieldUnit(filterData.yieldUnit);
        const performanceChart = initChart(CHART_TYPE.PERFORMANCE, 0);
        performanceChart.data.datasets[0].backgroundColor = [];
        performanceChart.id = (new Date()).getTime();
        if (filterData.yieldUnit === YieldUnits.LITERS_PER_100KM) {
            things.sort(sortByPerformance);
        } else {
            things.sort(inverseSortByPerformance);
        }
        if (data && data.length > 0) {
            for (const thing of things) {
                performanceChart.data.labels.push(thing.thingName);
                if (thing.thingName === currentThing.thingName) {
                    performanceChart.data.datasets[0].backgroundColor.push("rgb(255,0,0)");
                } else {
                    performanceChart.data.datasets[0].backgroundColor.push("rgb(54, 162, 235)");
                }
                performanceChart.data.datasets[0].data.push(thing.performanceValue);
                performanceChart.data.datasets[0].formattedTooltipData.label.push(thing.performanceValue + " " + performanceConversion);
            }
        }
        return [performanceChart];
    }

    function loadBreadcrumbSelector(data, selectedItem) {
        store.dispatch("breadcrumb/setEntitySelectorConfiguration", {
            name: i18n.t("equipments"),
            value: "thingId",
            text: "thingName"
        });
        const items = data.map(element => ({
            thingId: element.thingId,
            thingName: element.thingName
        }));
        store.dispatch("breadcrumb/setEntitySelectorItems", items);
        const item = {
            thingId: selectedItem.thingId,
            thingName: selectedItem.thingName
        };
        store.dispatch("breadcrumb/setSelectedItem", item);
    }

    function sortByPerformance(a, b) {
        if (a.performanceValue > b.performanceValue) {
            return 1;
        }
        if (a.performanceValue < b.performanceValue) {
            return -1;
        }
        return 0;
    }

    function inverseSortByPerformance(a, b) {
        if (a.performanceValue > b.performanceValue) {
            return -1;
        }
        if (a.performanceValue < b.performanceValue) {
            return 1;
        }
        return 0;
    }

    function calculatePerformanceData(things, configuration, reportBy) {
        const litersConversion = CONVERSIONS_LITERS[configuration];
        const performanceConversion = getYieldUnit(configuration);
        // const distanceConversion = CONVERSIONS_DISTANCE[configuration];
        const result = [];

        for (const thing of things) {
            const isHourmeterReport = reportBy === VARILLA_REPORT_BY.HOURMETER;
            const performanceMeasure = isHourmeterReport ? (thing.timeRunning / 3600) : thing.distance;
            const performanceValue = calcPerformance(thing.consumed, performanceMeasure, configuration);
            if (performanceValue > 0) {
                let consumed100km = 0;
                let consumed10h = 0;
                if (isHourmeterReport) {
                    consumed10h = calcPerformance(thing.consumed, performanceMeasure, YieldUnits.LITERS_PER_HOUR) * 10;
                } else {
                    consumed100km = calcPerformance(thing.consumed, thing.distance, YieldUnits.LITERS_PER_100KM);
                }
                let consumed = thing.consumed || 0;
                if (configuration === YieldUnits.MILES_PER_GALLON) {
                    consumed100km = parseToGallonPerMiles(consumed100km);
                }
                if (configuration === YieldUnits.MILES_PER_GALLON || configuration === YieldUnits.GALLONS_PER_HOUR || configuration === YieldUnits.HOURS_PER_GALLON) {
                    consumed = consumed * LITER_TO_GALLON;
                }
                const consumedFuel = consumed.toFixed(2) + " " + litersConversion;
                let consumedEveryFixedValue;
                if (isHourmeterReport) {
                    consumedEveryFixedValue = consumed10h + " " + litersConversion;
                } else {
                    consumedEveryFixedValue = consumed100km + " " + litersConversion;
                }
                const performanceByLiter = performanceValue + " " + performanceConversion;

                result.push({ thingName: thing.thingName, consumedFuel, performanceValue, consumedEveryFixedValue, performanceByLiter });
            }
        }
        return result;
    }

    async function getPerformanceChartTracksDetailsByFixedRange(idThing, filters) {
        const filterData = processFilters(filters);
        const timezone = store.getters["user/getEnterpriseTimezone"];
        const tracks = await performanceApi.getPerformanceTracksDetailsByFixedRange(idThing, filterData.reporFixedKey, timezone, filterData.reportBy);
        return await generateChartDetailsByData(tracks, idThing, filterData.reportBy, filterData.yieldUnit);
    }

    async function generateChartDetailsByData(tracks, idThing, reportBy) {
        const configThing = await thingApi.getVarillaThingConfigByThingIds([idThing]);
        tracks = groupChargeDischarge(tracks, reportBy);
        let categories = [];
        let mainXSerieName = i18n.t('performanceDetail.chartTracks.fuelSerie');
        const events = await eventsService.getEventsMapByThingId(idThing);
        let sourceWidth = 1600;
        const result = {
            chart: {
                type: 'column',
                zoomType: 'x'
            },
            credits: {
                enabled: false
            },
            xAxis: {
                lineWidth: 1,
                categories: [],
                tracks: [],
                min: 0,
                max: 30,
                opposite: false,
                labels: {
                    enabled: true, staggerLines: 1, step: 5,
                    formatter: (instance) => {
                        return categories[instance.value];
                    }
                },
                scrollbar: {
                    enabled: true
                }
            },
            yAxis: {
                lineWidth: 1,
                opposite: false
            },
            rangeSelector: {
                enabled: false
            },
            exporting: {
                enabled: false
            },
            tooltip: {
                //valueDecimals: 2,
                pointFormatter: function () {
                    if (this.series.name === mainXSerieName) {
                        return mainXSerieNameToolTip(tracks[this.index], events, configThing[idThing]);
                    }
                    return "";
                }
            },
            plotOptions: {
                column: {
                    stacking: 'normal',
                    dataLabels: {
                        enabled: true,
                        rotation: -90,
                        style: {
                            fontSize: "16px"
                        }
                    }
                }
            },
            series: []

        };
        const dataLabels = {
            enabled: true, formatter: function () {
                return this.y !== 0 ? this.y : null
            },
            style: { fontWeight: 'bold' }
        }
        if (tracks && tracks.length > 0) {
            const performanceSerie = {
                name: i18n.t('performanceDetail.chartTracks.fuelSerie'), data: [], color: "#37A2FF", stack: '1', dataLabels: dataLabels, turboThreshold: 0
            };
            const chargeSerie = {
                name: i18n.t('performanceDetail.chartTracks.chargedSerie'), data: [], stack: '1', color: "#39D553", dataLabels: dataLabels, turboThreshold: 0
            };
            const dischargeSerie = {
                name: i18n.t('performanceDetail.chartTracks.dischargedSerie'), data: [], stack: '1', color: "#D74242", dataLabels: dataLabels, turboThreshold: 0
            };
            const idlingSerie = {
                name: i18n.t('performanceDetail.chartTracks.idlingSerie'), data: [], stack: '1', color: "#000000", dataLabels: dataLabels, turboThreshold: 0
            };
            let max = 0;
            for (const track of tracks) {
                let fuelLevel, finalCharged, finalDischarged, finalIdling;
                fuelLevel = getFuelLevel(track) ? Number(unitParser.parseVolumen(getFuelLevel(track),store.getters['user/getInternationalization'].unit,false)) : 0;
                finalCharged = track.finalCharged ? Number(unitParser.parseVolumen(track.finalCharged,store.getters['user/getInternationalization'].unit,false)) : 0;
                finalDischarged = track.finalDischarged ? Number(unitParser.parseVolumen(track.finalDischarged,store.getters['user/getInternationalization'].unit,false)) : 0;
                finalIdling = track.finalIdling ? Number(unitParser.parseVolumen(track.finalIdling,store.getters['user/getInternationalization'].unit,false)) : 0;
                max = Math.max(fuelLevel, max);
                sourceWidth += 20;
                performanceSerie.data.push(
                    [
                        parseTimestamp(track.timestamp),
                        fuelLevel
                    ]
                );
                chargeSerie.data.push(
                    [
                        parseTimestamp(track.timestamp),
                        finalCharged
                    ]
                );
                dischargeSerie.data.push(
                    [
                        parseTimestamp(track.timestamp),
                        finalDischarged
                    ]
                );
                idlingSerie.data.push(
                    [
                        parseTimestamp(track.timestamp),
                        finalIdling
                    ]
                );
                categories.push(parseTimestamp(track.timestamp));
            }
            result.xAxis.categories = categories;
            result.xAxis.tracks = tracks;
            result.series.push(chargeSerie);
            result.series.push(dischargeSerie);
            result.series.push(idlingSerie);
            result.series.push(performanceSerie);
            result.yAxis.max = max + 30;
        }
        result.exporting.sourceWidth = sourceWidth;
        return result;
    }

    function mainXSerieNameToolTip(track, events, configTanks) {
        let fuelLevelsString = getFuelLevelString("TANK_1", track.fuelLevel1, configTanks) + getFuelLevelString("TANK_2", track.fuelLevel2, configTanks) + getFuelLevelString("TANK_3", track.fuelLevel3, configTanks) + getFuelLevelString("TANK_4", track.fuelLevel4, configTanks);
        let eventData = getEventData(track);
        let speed = track.speed && track.speed.toFixed(2) || 0;
        const tanksChargedDischarged = getDetailsChartChargesDischarge(track);
        // <br>${i18n.t('performanceDetail.mainToolTip.date')}: ${parseTimestamp(track.timestamp)}, ${i18n.t('performanceDetail.mainToolTip.fuelLevel')}: ${track.fuelLevel} ${i18n.t('performanceDetail.mainToolTip.fuelType')}, ${i18n.t('performanceDetail.mainToolTip.distance')}: ${track.distance} ${i18n.t('performanceDetail.mainToolTip.distanceType')}
        let result = `<div style="font-weight: bold;">${i18n.t('performanceDetail.mainToolTip.title')}</div>: ${track.thingName}`
        result += `<br>${i18n.t('performanceDetail.mainToolTip.fuelLevel')}: ${unitParser.parseVolumen(track.lastFuelLevel,store.getters['user/getInternationalization'].unit,true)}`
        
        result += `
            <br>
            ${i18n.t('performanceDetail.mainToolTip.engineState')}: ${i18n.t('performanceDetail.mainToolTip.engineStates.' + !!track.engineState)}, ${i18n.t('performanceDetail.mainToolTip.speed')}: ${unitParser.parseSpeed(speed, store.getters['user/getInternationalization'].unit, true)}${eventData}${tanksChargedDischarged}
            <br>
            <div style="font-weight: bold;">${i18n.t('performanceDetail.mainToolTip.odometer')}</div>: ${unitParser.parseDistance(track.distance, store.getters['user/getInternationalization'].unit, true)}
            <br>
            <div style="font-weight: bold;">${i18n.t('performanceDetail.mainToolTip.details')}</div>:${fuelLevelsString}
        `
        return result;
    }

    function getDetailsChartChargesDischarge(track) {
        let result = '';
        if (track.tanksCharged && Object.keys(track.tanksCharged).length > 0) {
            for (const key in track.tanksCharged) {
                result += `<br>&emsp;${i18n.t('performanceDetail.mainToolTip.charged')} ${i18n.t('performanceDetail.mainToolTip.tanks.' + key)}: ${unitParser.parseVolumen(track.tanksCharged[key],store.getters['user/getInternationalization'].unit,true)}`
            }
        }
        if (track.tanksDischarged && Object.keys(track.tanksDischarged).length > 0) {
            for (const key in track.tanksDischarged) {
                result += `<br>&emsp;${i18n.t('performanceDetail.mainToolTip.discharged')} ${i18n.t('performanceDetail.mainToolTip.tanks.' + key)}: ${unitParser.parseVolumen(track.tanksDischarged[key],store.getters['user/getInternationalization'].unit,true)}`
            }
        }
        return result;
    }

    function groupChargeDischarge(tracks, reportBy) {
        reportBy + "";
        let newTracks = JSON.parse(JSON.stringify(tracks));
        // let track = null;
        let result = [];
        if (tracks && tracks.length > 0) {
            for (const track of newTracks) {
                track.lastFuelLevel = track.fuelLevel > 0 ? track.fuelLevel : 0;
                const fuelLevel = (track.fuelLevel - (track.charged || 0));
                track.events = [];
                track.fuelLevel = fuelLevel > 0 ? fuelLevel : 0;
                if (track.charged && track.charged > 0) {
                    track.events.push({
                        charged: track.charged
                    });
                    track.finalCharged = track.charged;
                }
                if (track.discharged && track.discharged > 0) {
                    track.events.push({
                        discharged: track.discharged
                    });
                    track.finalDischarged = track.discharged;
                }
                if (track.idling && track.idling > 0) {
                    track.events.push({
                        idling: track.idling
                    });
                    track.finalIdling = track.idling;
                }
                if (track.fuelLevel === 0) {
                    track.finalIdling = 0;
                    track.finalDischarged = 0;
                    track.finalCharged = 0;
                }
                result.push(track);
            }
        }

        if (result.length > 0) {
            // if (tracks && tracks.length > 0 && lastTrack != tracks[tracks.length - 1]) {
            //     lastTrack.events.push({
            //         idling: lastTrack.finalIdling
            //     });
            //     generateChargeEvents(lastTrack);
            //     generateDischargeEvents(lastTrack);
            // }
            validateNegativeValues(result[result.length - 1]);
        }
        return result;
    }


    // function generateChargeEvents(lastTrack) {
    //     for (const key in lastTrack.charges) {
    //         if (lastTrack.charges[key]) {
    //             lastTrack.events.push({
    //                 charged: lastTrack.charges[key] > 0 ? lastTrack.charges[key] : 0,
    //                 tankKey: FUEL_LEVEL_KEY_TO_TANK_KEY[key]
    //             });
    //         }
    //     }
    // }

    // function generateDischargeEvents(lastTrack) {
    //     for (const key in lastTrack.discharges) {
    //         if (lastTrack.discharges[key]) {
    //             lastTrack.events.push({
    //                 discharged: lastTrack.discharges[key] > 0 ? lastTrack.discharges[key] : 0,
    //                 tankKey: FUEL_LEVEL_KEY_TO_TANK_KEY[key]
    //             });
    //         }
    //     }
    // }

    function validateNegativeValues(track) {
        track.finalIdling = track.finalIdling > 0 ? track.finalIdling : 0;
        track.finalCharged = track.finalCharged > 0 ? track.finalCharged : 0;
        track.finalDischarged = track.finalDischarged > 0 ? track.finalDischarged : 0;
    }

    function getEventData(track) {
        let result = "";
        for (const event of track.events) {
            if (event.discharged) {
                result += `<br>${i18n.t('performanceDetail.mainToolTip.discharged')}: ${unitParser.parseVolumen(event.discharged ,store.getters['user/getInternationalization'].unit,true)}`
            }            
            if (event.charged) {
                result += `<br>${i18n.t('performanceDetail.mainToolTip.charged')}: ${unitParser.parseVolumen(event.charged ,store.getters['user/getInternationalization'].unit,true)}`
            }
            if (event.idling) {
                result += `<br>${i18n.t('performanceDetail.mainToolTip.idling')}: ${unitParser.parseVolumen(event.idling,store.getters['user/getInternationalization'].unit,true)}`                
            }
        }
        return result;
    }

    function getFuelLevelString(key, fuelLevel, configTanks) {
        const tank = TANK_KEY_CONFIG_FIELD[key];
        return (configTanks && configTanks[tank] && fuelLevel) ? `<br>${i18n.t('performanceDetail.mainToolTip.tanks.' + key)}: ${unitParser.parseVolumen(fuelLevel,store.getters['user/getInternationalization'].unit,true)}`: "";
        
        
    }

    function getFuelLevel(track) {
        // if (track.charged) {
        //     return !track.fuelLevel ? 0 : (track.fuelLevel - track.charged);
        // }
        return !track.fuelLevel ? 0 : (track.fuelLevel)
    }

    function getMapPopupTemplate(thingName, lastTrackTimestampTranslation, lastTrackTimestampColor, lastTrackTimestampValue, lastCommunicationTimestampTranslation, lastCommunicationTimestampColor, lastCommunicationTimestampValue, goToGoogleTranslation, lat, lng) {
        return `
            <h4>${thingName}</h4>
            <hr>${lastTrackTimestampTranslation}: <div class="chip-track">
                    <div class="chip-content">${lastTrackTimestampValue}</div>
                </div><br> 
                ${lastCommunicationTimestampTranslation}: <div class="chip-communication">
                    <div class="chip-content">${lastCommunicationTimestampValue}</div>
                    </div><br>
                <a href="https://www.google.com/maps/search/?api=1&query=${lat}%2C${lng}" class="googleLink" target="_blank">${goToGoogleTranslation}</a>
                <style scoped>
                    .chip-track{  display: inline-flex;
                            flex-direction: row;
                            cursor: default;
                            height: 18px;
                            border: ${lastTrackTimestampColor};
                            padding-top: 1px;
                            font-size: 13px;
                            color: ${lastTrackTimestampColor};
                            font-family:"Open Sans", sans-serif;
                            white-space: nowrap;
                            align-items: center;
                            border-radius: 5px;
                            border-style: solid;
                            border-width: 1px;
                            vertical-align: middle;
                            text-decoration: none;
                            justify-content: center;
                        }
                    .chip-communication{  display: inline-flex;
                            flex-direction: row;
                            cursor: default;
                            height: 18px;
                            border: ${lastCommunicationTimestampColor};
                            padding-top: 1px;
                            font-size: 13px;
                            color: ${lastCommunicationTimestampColor};
                            font-family:"Open Sans", sans-serif;
                            white-space: nowrap;
                            align-items: center;
                            border-radius: 5px;
                            border-style: solid;
                            border-width: 1px;
                            vertical-align: middle;
                            text-decoration: none;
                            justify-content: center;
                        }
                    .chip-content{ 
                            cursor: inherit;
                            display: flex;
                            align-items: center;
                            user-select: none;
                            white-space: nowrap;
                            padding-left: 8px;
                            padding-right: 8px;
                        }
                </style>
            `;
    }

    function parseDate(longDate) {
        const date = new Date(longDate);
        return dateformat(date, "dd/mm/yy HH:MM:ss");
    }

    function getUserColors() {
        const themes = JSON.parse(localStorage.getItem('themes'));
        const darkLocalStorage = localStorage.getItem("dark") === "true";
        if (darkLocalStorage) {
            return { primary: themes.dark.primary, secondary: "white", accent: themes.dark.accent };
        }
        return { primary: themes.light.primary, secondary: "white", accent: themes.light.accent }
    }

    return {
        getPerformanceSummary,
        getPerformanceDashboard,
        getDetailsData,
        getPerformanceChartComparation,
        loadBreadcrumbSelector,
        getPerformanceChartTracksDetailsByFixedRange,
        getUserColors,
        generateReportByData,
        generateChartDetailsByData,
        parseDate
    }
})();