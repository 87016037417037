import http from "@/middleware";

export const eventsApi = {
    /**
     * Obtiene el mapa de eventos usando como clave el numero de evento
     * @param {*} filters
     * @returns
     */
    async getEventsMapByThingId(thingId) {
        return await http.get('/api/events/getEventsMapByThingId/' + thingId);
    }
}

export default eventsApi
