import http from "@/middleware";

export const performanceApi = {
  async getPerformanceDataFixedRange(filters) {
    const result = await http.post("/api/varilla-report/performance-fixed-range", filters);
    return result.data;
  },

  async getPerformanceDataFixedRangeByThingTypeAndBrand(filters) {
    const result = await http.post("/api/varilla-report/performance-best-brand-serviceType", filters);
    return result.data;
  },

  async getPerformanceTracksDetailsByFixedRange(idThing, dateRange, timezone, reportBy) {
    const result = await http.post('/api/varilla-report/performanceTracksDetailsByFixedRange', { idThing, dateRange, timezone, reportBy });
    return result.data;
  },
  async getPerformanceDataCustomTime(filters) {
    const lang = localStorage.getItem("locale");
    filters.lang = lang;
    const result = await http.post("/api/varilla-report/performanceCustomTime", filters);
    return result.data;
  },
};

export default performanceApi;
