/* eslint-disable indent */
import eventsApi from '@/api/events.api';

/**
 * Servicio para reportar bugs
 */
export const eventsService = (function () {
    'use strict'

    /**
     * Obtiene el mapa de eventos usando como clave el numero de evento
     * @param {*} reportBug
     */
    async function getEventsMapByThingId(thingId) {
        const response = await eventsApi.getEventsMapByThingId(thingId)
        return response.data;
    }

    return {
        getEventsMapByThingId
    }
})()
